.header {
  width: 100%;
  height: 92px;
  border-bottom: 1px solid #E65634;

  @media screen and (max-width: 1290px){
    height: 120px;
  }

  .header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 107px 0 197px;
    height: 100%;


    @media screen and (max-width: 1699px){
      padding: 0 65px 65px;
    }

    @media screen and (max-width: 1290px){
      flex-direction: row;
      justify-content: space-between;
    }

    @media screen and (max-width: 625px){
      padding: 0 25px;
    }

  }

  .header-left{
    display: flex;
    align-items: center;


    &_logo{
      width: 65px;
      height: 65px;
      position: relative;

      img{
        width: 100%;
        height: 100%;
      }
    }

    &_search{
      margin-left: 55px;
      display: flex;
      align-items: center;
      position: relative;

      @media screen and (max-width: 1290px){

      }

      input{
        margin-right: 20px;
        background: #11112280 0 0 no-repeat padding-box;
        width: 284px;
        height: 27px;
        padding: 3px 20px;
        border: 0;
        font-size: 14px;
        color: #FFFFFF;
      }

      .orange-border-button{
        margin: 0;
      }
    }

    &_cart{
      width: 28px;
      height: 26px;

      img{
        width: 100%;
        height: 100%;
      }
    }
  }

  .header-right{
    display: flex;
    align-items: center;

    .header-right_logIn{
      display: flex;
      align-items: center;

      .icon-discord{
        margin-left: 10px;

        a{
          width: 100%;
          height: 100%;
        }
      }

      .icon-twitter{
        margin-left: 10px;

        a{
          width: 100%;
          height: 100%;
        }

        svg{
          width: 32px;
          height: 32px;
        }
      }
    }

    &_cart{
      margin-left: 17px;
      cursor: pointer;
    }
  }
}

.header-left_search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 324px;
  max-height: 200px;
  background-color: rgba(0, 0, 0, 0.53);
  z-index: 999;
  padding: 0;
  margin: 0;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  overflow: auto;
}

.header-left_search-results li {
  list-style: none;
  padding: 10px;
  color: white;
  cursor: pointer;
}

.header-left_search-results li:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
