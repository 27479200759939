.App {
  text-align: center;
}

button{
  cursor: pointer;
  font-family: 'Lexend-Medium', sans-serif;
}

p, h1, h2, h3, h4, h5, h6{
  margin: 0;
  padding: 0;
}

a{
  text-decoration: none;
}


footer{
  color: #D8D8D8;
  font-size: 16px;
  margin-bottom: 70px;
  margin-left: 290px;
  text-align: left;
}
