@font-face {
  font-family: 'Lexend-Regular';
  src: url('./fonts/Lexend-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend-SemiBold';
  src: url('./fonts/Lexend-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend-Medium';
  src: url('./fonts/Lexend-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend-ExtraLight';
  src: url('./fonts/Lexend-ExtraLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lexend-Bold';
  src: url('./fonts/Lexend-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar {
  width: 3px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #E65634;
  border-radius: 10px;
}

body {
  margin: 0;
  font-family: 'Lexend-Medium', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(32,20,36);
  background-repeat: no-repeat;
  overflow-x: hidden;
  background-image:  url("./images/bg2.png"), url("./images/bg.png");
}

/*button{*/
/*  font-family: 'Lexend-Medium', sans-serif;*/
/*}*/

@media screen and (min-width: 1920px){
  body{
    background-size: 100%;
  }
}

a:hover svg g{
  stroke: white;
}

/*//@media screen and (min-width: 1920px){*/
/*//  background-size: cover;*/
/*//}*/
/*//@media screen and (max-width: 1899px){*/
/*//  background-position: center;*/
/*//}*/
/*}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
